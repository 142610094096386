#brand {
  margin-top: 8px;
}

.formInputBox{
    border: 1px solid #941616;
    background: rgba(148, 22, 22,0.5); color: white;
    border-radius: 5px;
}
.activate-button {
    color: #fff !important;
    background-color: #941616 !important;
    border-color: #941616 !important;
}
.text-orange {
    color:#a4a4a4;
    text-decoration: none;
}
.text-blue {
    text-decoration: underline;
    color: rgba(148, 22, 22,1);
}


.savings-bar-container {
    position: relative;
    box-shadow: 1px 1px lightgrey;
    width: 100%;
    background:rgba(87,87,87,1);
    height: 4em;
    overflow-x: hidden;
    border-radius: 5px;
    z-index: 1;
}

.savings-bar {
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: -120%;
    background:rgba(148, 22, 22,1);
    width: 100%;
    height: 100%;
    z-index: 100;
//animation: slideIn 1s forwards;

}
#pane-3 {
    background-image: url('/images/gmgcomputer.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

#pane-3-text {
  color: white;
}

.large-activate-button {
  background-color: white !important;
  color: #941616 !important;
}
.chevron {
  display: none;
}

.logoImage{
    max-height:60px;
}
.footer{
    position: fixed;
    bottom:0;
    height: 58px;
    border-top: 4px solid #941616;
    background: #333333;
    width: 100%;
    color: #FFF;
}

.carousel-control.left, .carousel-control.right {
  color: #941616;
}
.carousel-control.left, .carousel-control.right {
  color: #941616;
}
.carousel-indicators li {
  border: 1px solid #941616;
}
/* Portrait*/
@media only screen
  and (min-device-width: 1024px)
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait)  {
    #pane-3 {
        background-image: url('/images/gmgmobile.jpg');
  }

}

@media only screen
    and (min-device-width: 1366px)
    and (max-device-width: 1024px)
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: landscape)  {
      #pane-3 {
          background-image: url('/images/gmgmobile.jpg');
    }

}

@media (max-width: 768px)  {
    #pane-3{
        background-image: url('/images/gmgmobile.jpg');
    }
}

.nav-border {
    border-bottom: 2px solid #941616;
}

.details-button {
    width: 100%;
    color: white;
    height: 3em;
    background: rgba(148, 22, 22,1);
    border-color: rgba(148, 22, 22,1);
    border-style: solid;
}
.details-button:disabled {
    width: 100%;
    color: white;
    height: 3em;
    background: lightgrey;
    border-color: lightgrey;
    border-style: solid;
    cursor:not-allowed; 
  }
.details-chevron {
    display: none;
}

.details-button-text {
    font-size: 1.5em;
}

.button-chevron {
    display: none;
}

.button-chevron-left {
    display: none;
}

.spinner {
    background: #941616;
    margin: 0 !important;
    width: 40px;
    height: 40px;
    margin: 100px auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}

.sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #941616;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
