@import 'colors';

.gmg-navbar {
  background: $gmg-red;
  color: $white;
  padding-top: 5px;
  padding-bottom: 25px;
}

p.stryde-bold {
  color: $gmg-red;
}

.ui-datepicker-next span {
	background-image: url('/images/ui-icons_ffffff_256x240.png') !important;
	zoom: 150%; 
    -moz-transform: scale(1.5); 
    -webkit-zoom: 1.5; 
    -ms-zoom: 1.5;
}

.ui-datepicker-prev span {
    background-image: url('/images/ui-icons_ffffff_256x240.png') !important;
    zoom: 150%; 
    -moz-transform: scale(1.5); 
    -webkit-zoom: 1.5; 
    -ms-zoom: 1.5;
}

.ui-datepicker-header {
    background: $gmg-red;
  }

  .ui-state-active {
      background: $gmg-red !important;
      color: white !important;
      border: none !important;
  }

  .time:hover {
      background: $gmg-red;
  }

  .confirm {
      background: $gmg-red;
  }

#time-select-header{
	background: $gmg-red;
}

.ddlTimezone, .form-group{
	padding-top:5px;
}

#txt-appointment-message{
	position:relative;
	top:5px;
	left:10px;
	font-size: 1.5em;
}